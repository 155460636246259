import PublicIcon from '@mui/icons-material/Public';
import {
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TSortOrder } from 'common/defines/constants';
import { generateKml } from 'common/utils/generate-kml';
import { renderSortIcon } from 'common/utils/util';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { IPopulationCount } from 'interfaces/fish-migration';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';

interface IHeader {
  key: HeaderItem;
  label: string;
  sortOrder: TSortOrder;
}

enum HeaderItem {
  ID = 'ID',
  DATE = 'DATE',
}

const initialListHeaders = [
  { key: HeaderItem.ID, label: 'trans.id', sortOrder: null },
  { key: HeaderItem.DATE, label: 'trans.dates', sortOrder: null },
];

const CustomTableCell = styled(TableCell)({
  padding: '14px',
});

interface IReducedTableData extends Pick<IPopulationCount, 'tagId' | 'species'> {
  listData: (IPopulationCount & { isSelected: boolean })[];
}

interface AquaticSpeciesTrackingTableProps {
  filteredDateRange: IPopulationCount[];
}

const AquaticSpeciesTrackingTable: FC<AquaticSpeciesTrackingTableProps> = ({ filteredDateRange }) => {
  const [tableHeaders, setTableHeaders] = useState<IHeader[]>(initialListHeaders);
  const [sortInfo, setSortInfo] = useState<{ key: HeaderItem; sortOrder: TSortOrder }>({
    key: HeaderItem.ID,
    sortOrder: null,
  });

  const [selectedDateOption, setSelectedDateOption] = useState<Record<string, IPopulationCount>>();

  const {
    aquaticSpeciesTracking: { listSelectedIds },
  } = useAppSelector(fishMigrationSelector);
  const { t } = useTranslation();

  const filteredTableData = filteredDateRange.filter((item) => {
    return listSelectedIds.includes(item.tagId);
  });

  const reducedTableData = filteredTableData.reduce<IReducedTableData[]>((acc, cur) => {
    const foundItem = acc.find((item) => item.tagId === cur.tagId);
    if (foundItem) {
      foundItem.listData.push({ ...cur, isSelected: false });
      foundItem.listData.sort((first, second) => moment(first.date).valueOf() - moment(second.date).valueOf());
      foundItem.listData = foundItem.listData.map((item, index) => ({
        ...item,
        isSelected: index === 0 ? true : false,
      }));
    } else {
      const { tagId, species } = cur;
      acc.push({ tagId, species, listData: [{ ...cur, isSelected: false }] });
    }

    return acc;
  }, []);

  const sortFunction = (first: IReducedTableData, second: IReducedTableData) => {
    const { key, sortOrder } = sortInfo;
    if (key === HeaderItem.ID) {
      return sortOrder === 'ASC' ? first.tagId - second.tagId : second.tagId - first.tagId;
    } else if (key === HeaderItem.DATE) {
      return sortOrder === 'ASC'
        ? moment(first.listData[0].date).valueOf() - moment(second.listData[0].date).valueOf()
        : moment(second.listData[0].date).valueOf() - moment(first.listData[0].date).valueOf();
    }
    return 0;
  };

  const sortedTableData = [...reducedTableData].sort((a, b) => sortFunction(a, b));

  useEffect(() => {
    let newSelectedDateOption: Record<string, IPopulationCount> = {};
    sortedTableData.forEach((item) => {
      newSelectedDateOption[item.tagId.toString()] = item.listData[0];
    });
    setSelectedDateOption(newSelectedDateOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortTable = (key: HeaderItem, sortOrder: TSortOrder) => {
    let newSortOrder: TSortOrder = null;
    if (!sortOrder) newSortOrder = 'DESC';
    if (sortOrder === 'ASC') newSortOrder = 'DESC';
    if (sortOrder === 'DESC') newSortOrder = 'ASC';
    setSortInfo({ key, sortOrder: newSortOrder });

    const newTableHeaders = tableHeaders.map((header) =>
      header.key === key ? { ...header, sortOrder: newSortOrder } : { ...header, sortOrder: null }
    );
    setTableHeaders(newTableHeaders);
  };

  const renderTableHeaders = (header: IHeader) => {
    return (
      <CustomTableCell key={header.key}>
        {t(header.label)}
        <SvgIcon
          component={renderSortIcon(header.sortOrder)}
          inheritViewBox
          sx={{ fontSize: '14px', cursor: 'pointer', ml: '4px' }}
          onClick={() => handleSortTable(header.key, header.sortOrder)}
        />
      </CustomTableCell>
    );
  };

  const handleChangeDate = (e: SelectChangeEvent<unknown>, row: IReducedTableData) => {
    const selectedId = e.target.value as string;
    const selectedPopulationCount = row.listData.find((item) => item._id === selectedId);
    const newSelectedDateOption = cloneDeep(selectedDateOption) ?? {};
    newSelectedDateOption[row.tagId.toString()] = selectedPopulationCount!;

    setSelectedDateOption(newSelectedDateOption);
  };

  const renderSelectDates = (row: IReducedTableData) => {
    const { listData, tagId } = row;
    const selectedId = selectedDateOption?.[tagId]?._id;
    return (
      <Select onChange={(e) => handleChangeDate(e, row)} size="small" value={selectedId || listData[0]._id}>
        {listData.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {moment(item.date).format(DATE_VISUALIZE_FORMAT)}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderDatesContent = (row: IReducedTableData) => {
    const { listData } = row;

    if (listData.length > 10) {
      return renderSelectDates(row);
    }

    return listData.map((item) => moment(item.date).format(DATE_VISUALIZE_FORMAT)).join(', ');
  };

  const handleClickIcon = (row: IReducedTableData) => {
    const { listData } = row;
    const name = `aquatic_species_tracking`;
    const kmlContent = generateKml({
      name,
      properties: listData.map((item) => {
        const { date, lon, lat, tagId } = item;
        return {
          name: `aquatic_species_tracking_${moment(date).format(DATE_VISUALIZE_FORMAT)}`,
          longitude: lon,
          latitude: lat,
          tagId,
          date: date.toString(),
        };
      }),
    });
    const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
    saveAs(blob, `aquatic_species_tracking.kml`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.slice(0, 1).map((header) => renderTableHeaders(header))}
            <CustomTableCell>{t('trans.species')}</CustomTableCell>
            {tableHeaders.slice(1, 2).map((header) => renderTableHeaders(header))}
            <CustomTableCell></CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTableData.map((row, index) => {
            const { tagId, species } = row;
            return (
              <TableRow key={index}>
                <CustomTableCell sx={{ width: '65px' }}>{tagId}</CustomTableCell>
                <CustomTableCell>{species}</CustomTableCell>
                <CustomTableCell sx={{ width: '200px' }}>{renderDatesContent(row)}</CustomTableCell>
                <CustomTableCell>
                  {<PublicIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleClickIcon(row)} />}
                </CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AquaticSpeciesTrackingTable;
