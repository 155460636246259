import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import { distance as turfDistance, point as turfPoint } from '@turf/turf';
import { DIFFERENT_COLOR } from 'common/defines/constants';
import { generateComparedTime } from 'common/utils/time';
import PopupParagraph from 'components/Common/PopupParagraph';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import useGetDataPopulationCount from 'hooks/fish-migration/useGetDataPopulationCount';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Marker, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { fishMigrationSelector } from 'store/slices/map-view/fishMigrationAnalytics';

export const useAquaticSpeciesTrackingAnalytics = () => {
  const [hoveredMarkerDetail, setHoveredMarkerDetail] = useState<IPopulationCount>();
  const { t } = useTranslation();
  const {
    aquaticSpeciesTracking: { listSelectedIds, selectedTimeFrame },
  } = useAppSelector(fishMigrationSelector);

  const comparedTime = generateComparedTime(selectedTimeFrame);

  const { populationCountData } = useGetDataPopulationCount();

  const listPopupMarker = (item: IPopulationCount) => {
    return [
      { key: 'Id', value: item.tagId },
      { key: t('trans.species'), value: item.species },
      { key: t('trans.date'), value: moment(item.date).format(DATE_VISUALIZE_FORMAT) },
    ];
  };

  const setTagIds = [...new Set(populationCountData?.map((item) => item.tagId))];

  const getColor = (id: number) => {
    return DIFFERENT_COLOR[setTagIds.indexOf(id)];
  };

  const reducedDrawingLinesObj = populationCountData?.reduce<Record<string, IPopulationCount[]>>((acc, cur) => {
    const key = 'tag' + cur.tagId;
    if (!acc[key]) {
      acc[key] = [cur];
    } else {
      acc[key].push(cur);
      acc[key].sort((a: IPopulationCount, b: IPopulationCount) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }
    return acc;
  }, {});

  const geoJsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: Object.values(populationCountData?.length ? reducedDrawingLinesObj : {})
      ?.filter((listItems) => {
        const [first] = listItems;
        return listSelectedIds.includes(first.tagId);
      })
      ?.filter((listItems) => {
        const first = listItems[0];
        const last = listItems[listItems.length - 1];
        const firstDate = moment(first.date);
        const lastDate = moment(last.date);

        if (firstDate.isAfter(comparedTime) && lastDate.isAfter(comparedTime)) {
          return true;
        }
        return false;
      })
      ?.map((listItems) => {
        const [first, last] = listItems;

        const firstCoordinate = [first.lon, first.lat];
        const lastCoordinate = [last.lon, last.lat];

        const from = turfPoint(firstCoordinate);
        const to = turfPoint(lastCoordinate);

        const distance = turfDistance(from, to, { units: 'meters' });

        return {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [firstCoordinate, lastCoordinate],
          },
          properties: {
            color: getColor(first.tagId),
            distance,
          },
        };
      }) as GeoJSON.Feature[],
  };

  const renderDrawingLines = (
    <Source id="line-source" type="geojson" data={geoJsonData}>
      <Layer
        id="aquatic-species-tracking-lines"
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': 3,
        }}
      />
      <Layer
        id="arrow-layer"
        type="symbol"
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 100, // Distance between arrows in pixels
          'text-field': '▶', // Unicode arrow character
          'text-size': 30,
          'text-keep-upright': false,
          'text-rotation-alignment': 'map',
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'text-pitch-alignment': 'viewport',
        }}
        paint={{
          'text-color': ['get', 'color'],
        }}
      />
    </Source>
  );

  const renderPinPointList = populationCountData
    ?.filter((item) => {
      return listSelectedIds.includes(item.tagId);
    })
    ?.filter((item) => {
      if (moment(item.date).isAfter(comparedTime)) return true;
      return false;
    })
    ?.map((item) => {
      return (
        <Marker longitude={item.lon} latitude={item.lat} anchor="bottom" key={item._id}>
          <Box
            position="relative"
            sx={{
              height: '24px',
              width: '24px',
            }}>
            <PlaceIcon
              fontSize="large"
              sx={{ color: getColor(item.tagId) }}
              onMouseEnter={() => setHoveredMarkerDetail(item)}
              onMouseLeave={() => setHoveredMarkerDetail(undefined)}
            />
            {item._id === hoveredMarkerDetail?._id && (
              <Box
                sx={{
                  width: '180px',
                  position: 'absolute',
                  top: '-70px',
                  left: '-90px',
                  backgroundColor: (theme) => theme.palette.background.default,
                  border: 0,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '12px',
                  padding: '8px 12px',
                  zIndex: 100,
                }}>
                {listPopupMarker(item).map((row, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PopupParagraph value={row.key} variant="label" />
                    <PopupParagraph value={row.value} variant="content" />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Marker>
      );
    });

  const layerAquaticSpeciesTracking = populationCountData?.length ? (
    <>
      {renderDrawingLines}
      {renderPinPointList}
    </>
  ) : null;

  return { layerAquaticSpeciesTracking };
};
