import { Box } from '@mui/material';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import React, { FC } from 'react';
import { Marker } from 'react-map-gl';
import PopupParagraph from './Common/PopupParagraph';
import { clientLengthUnitMeasurement } from './MapView/RightBar/IssuesTab/utils';

interface AquaticSpeciesTrackingPopupProps {
  hoverInfo: any;
}

const AquaticSpeciesTrackingPopup: FC<AquaticSpeciesTrackingPopupProps> = ({ hoverInfo }) => {
  const { lengthSetting } = useQueryClientSetting();
  if (!hoverInfo) return null;

  const { properties } = hoverInfo;

  return (
    <Marker
      longitude={properties?.longX}
      latitude={properties?.latY}
      anchor={'bottom'}
      style={{ zIndex: 10 }}
      offset={[0, 0]}>
      <Box
        sx={{
          width: '180px',
          position: 'absolute',
          top: '-40px',
          left: '-90px',
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '12px',
          padding: '8px 12px',
          zIndex: 100,
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <PopupParagraph value="Distance: " variant="label" />
          <PopupParagraph
            value={`${clientLengthUnitMeasurement(properties?.distance, lengthSetting)} ${lengthSetting.unit}`}
            variant="content"
          />
        </Box>
      </Box>
    </Marker>
  );
};

export default AquaticSpeciesTrackingPopup;
