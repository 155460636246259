import PublicIcon from '@mui/icons-material/Public';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { filteredPopulationCountData } from 'common/utils/filter';
import { generateKml } from 'common/utils/generate-kml';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { saveAs } from 'file-saver';
import { IPopulationCount } from 'interfaces/fish-migration';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllPopulationCountAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeFocusedTagState } from 'store/slices/map-view/fishMigrationAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const PopulationCountAnalytics = () => {
  const [tableData, setTableData] = useState<IPopulationCount[]>([]);

  const { analyticId } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useQuery([QUERY_KEY.POPULATION_COUNT_ANALYTIC, analyticId], () => getAllPopulationCountAnalytic(analyticId || ''), {
    enabled: !!analyticId,
    onSuccess: (res) => {
      const resData = res.data as IPopulationCount[];
      const filteredData = filteredPopulationCountData(resData);

      setTableData(filteredData);
    },
  });

  const handleClickTagId = (row: IPopulationCount) => {
    dispatch(
      changeFocusedTagState({
        info: {
          id: row._id,
          long: row.lon,
          lat: row.lat,
        },
        isFlyDone: false,
      })
    );
  };

  const handleClickIcon = (row: IPopulationCount) => {
    const { lon, lat, date, tagId } = row;
    const formatDate = moment(date).format(DATE_VISUALIZE_FORMAT);
    const name = `population_count_${formatDate}`;
    const kmlContent = generateKml({
      name,
      properties: [
        { longitude: lon, latitude: lat, name: `population_count_${tagId}`, tagId: tagId, date: date.toString() },
      ],
    });
    const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
    saveAs(blob, `population_count_${formatDate}.kml`);
  };

  const renderCoordinates = (row: IPopulationCount) => {
    return (
      <Tooltip
        title={
          <>
            <Typography>
              {t('trans.longitude')}: {row.lon}
            </Typography>
            <Typography>
              {t('trans.latitude')}: {row.lat}
            </Typography>
          </>
        }
        placement="top-end">
        <PublicIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleClickIcon(row)} />
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 'fit-content',
        width: '100%',
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        padding: '20px',
      }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>{t('trans.id')}</CustomTableCell>
              <CustomTableCell sx={{ width: '150px' }}>{t('trans.species')}</CustomTableCell>
              <CustomTableCell sx={{ width: '150px' }}>{t('trans.power_signal_strength')}</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => {
              const { tagId, species, power } = row;
              return (
                <TableRow key={index}>
                  <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleClickTagId(row)}>
                    {tagId}
                  </TableCell>
                  <TableCell>{species}</TableCell>
                  <TableCell>{power}</TableCell>
                  <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {renderCoordinates(row)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PopulationCountAnalytics;
