interface KmlProperty {
  name: string;
  longitude: number;
  latitude: number;
  [key: string]: string | number;
}
interface KmlInfo {
  name: string;
  description?: string;
  properties: KmlProperty[];
}

export const generateKml = (kmlInfo: KmlInfo) => {
  const { name, description, properties } = kmlInfo;
  const generateExtendedData = (key: string, value: string) => {
    return `   <Data name="${key}">
            <value>${value}</value>
          </Data>`;
  };

  const generateListExtendedData = (data: KmlProperty) => {
    const { name, longitude, latitude, ...rest } = data;

    return `<Placemark>
        <name>${name}</name>
        <ExtendedData>
          ${Object.entries(rest).map(([key, value]) => generateExtendedData(key, value.toString()))}
        </ExtendedData>
        <Point>
          <coordinates>${longitude},${latitude}</coordinates>
        </Point>
      </Placemark>`;
  };

  return `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>
      <name>${name}</name>
      ${description ? `<description>${description}</description>` : ''}
      ${properties.map((prop) => generateListExtendedData(prop))}
    </Document>
  </kml>`;
};
