import { IPopulationCount } from 'interfaces/fish-migration';

export const filteredPopulationCountData = (data: IPopulationCount[]) => {
  if (!data || data.length === 0) return [];

  const filteredData = data?.reduce<IPopulationCount[]>((acc, cur) => {
    const foundItem = acc.find((item) => item.tagId === cur.tagId);
    if (foundItem) {
      if (foundItem.power < cur.power) {
        foundItem.power = cur.power;
      }
    } else {
      acc.push(cur);
    }
    return acc;
  }, []);

  return filteredData;
};
