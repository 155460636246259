import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodEnum } from 'common/defines/clients';
import { RootState } from 'store/store';

interface IFocusTag {
  info: {
    id: string;
    long: number;
    lat: number;
  };
  isFlyDone: boolean;
}

interface IFishMigration {
  populationCount: {
    focusTag: IFocusTag;
  };
  aquaticSpeciesTracking: {
    listSelectedIds: number[];
    selectedTimeFrame: PeriodEnum;
  };
}

const initialState: IFishMigration = {
  populationCount: {
    focusTag: {
      isFlyDone: true,
      info: {
        id: '',
        long: 0,
        lat: 0,
      },
    },
  },
  aquaticSpeciesTracking: {
    listSelectedIds: [],
    selectedTimeFrame: PeriodEnum.MAX,
  },
};

export const fishMigrationSlice = createSlice({
  name: 'fishMigration',
  initialState,
  reducers: {
    changeFocusedTagState: (state, action: PayloadAction<IFocusTag>) => {
      state.populationCount.focusTag = action.payload;
    },
    changeListSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.aquaticSpeciesTracking.listSelectedIds = action.payload;
    },
    changeSelectedTimeFrame: (state, action: PayloadAction<PeriodEnum>) => {
      state.aquaticSpeciesTracking.selectedTimeFrame = action.payload;
    },
  },
});

export const { changeFocusedTagState, changeListSelectedIds, changeSelectedTimeFrame } = fishMigrationSlice.actions;

export const fishMigrationSelector = (state: RootState) => state.fishMigration;

export default fishMigrationSlice.reducer;
